import { inject, Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { LanguageService } from './language/language.service';
import { EnvironmentService } from './environment.service';
import { ArrayService } from './array.service';
import { UserService } from './user/user.service';
import { isDesktop, isMobile } from '../helpers/device';

@Injectable({
  providedIn: 'root'
})
export class BankingService {
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _languageService = inject(LanguageService);
  private _env = inject(EnvironmentService);
  private _array = inject(ArrayService);
  private _user = inject(UserService);


  /**
   * All payment list
   */
  public paymentList$: Observable<any>;

  /**
   * Deposit payment list
   */
  public depositPaymentList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Withdrawal payment list
   */
  public withdrawalPaymentList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Custom Footer Images list
   */
  private _customImagesForFooterPayments = [
    'devcode-applepay', 'devcode-interac', 'devcode-picksellpay', 'devcode-volt_bankuberweisung', 'devcode-nodapay_uberweisungen',
    'devcode-funanga', 'devcode-bank_transfer'
  ];

  /**
   * Payments with own image
   */
  private readonly _paymentsOwnImage = {
    interac_finteqhub: {
      footer: {
        de: '/assets/svg/payment-methods/footer/interac_finteqhub.svg',
        at: '/assets/svg/payment-methods/footer/interac_finteqhub.svg',
        default: '/assets/svg/payment-methods/footer/interac_finteqhub.svg'
      },
      color: {
        de: '/assets/svg/payment-methods/color/interac_finteqhub.svg',
        at: '/assets/svg/payment-methods/color/interac_finteqhub.svg',
        default: '/assets/svg/payment-methods/color/interac_finteqhub.svg'
      }
    },
  };

  getAllPayments() {
    this.paymentList$ = this._languageService.langChange$.pipe(
      switchMap(() => {
        return forkJoin([
          this.getPaymentMethodsByType('deposit', Number(this._user.auth)),
          this.getPaymentMethodsByType('cashout', Number(this._user.auth)),
        ]);
      }),
      tap((list) => {
        list[0] = list[0].filter((e) => isDesktop() && !e.HideOnDesk || isMobile());
        list[1] = list[1].filter((e) => isDesktop() && !e.HideOnDesk || isMobile());
        this.depositPaymentList$.next(list?.[0]);
        this.withdrawalPaymentList$.next(list[1]);
      }),
      map(([dep, cash]) => this._array.uniqueBy('id', [...dep, ...cash])),
      share()
    );
  }

  /**
   * Get payment methods from CMS by type
   */
  getPaymentMethodsByType(type: string, sort_auth_player: number) {
    return this._api.paymentList({type, sort_auth_player}).pipe(
      map(response => this._mapper.mapCmsData(response.data, {
        id: 'id',
        slug: 'slug'
      })),
      map((list: any[]) => list.filter(e => e.id)),
      map((payments) => {
        return payments.map(e => {
          e.img = this._paymentHasOwnImage(e.slug) ?
            this._paymentsOwnImage[e.slug].color[this._env.env.country.short] || this._paymentsOwnImage[e.slug].color.default :
            'https://cdn.softswiss.net/logos/payments/color/' + e.slug + '.svg';
          e.footerImg = this._paymentHasOwnImage(e.slug) ?
            this._paymentsOwnImage[e.slug].footer[this._env.env.country.short] || this._paymentsOwnImage[e.slug].footer.default :
            'https://cdn.softswiss.net/logos/payments/white/' + e.slug + '.svg';
          e.ourImg = `/assets/svg/payment-methods-new/${e.slug}.svg`;
          return e;
        });
      }),
      map((list) => {
        const resultArr = this._customImagesForFooterPayments
          .map((elem) => list.find((e) => e.slug === elem))
          .filter(e => !!e);
        if (resultArr[0]) {
          resultArr.map((e) => e.footerImg = `/assets/svg/payment-methods-new/${e.slug}-custom.svg`);
        }
        return list;
      })
    );
  }

  /**
   * Does payment have own img
   * @payment
   */
  private _paymentHasOwnImage(payment: string) {
    return Object.keys(this._paymentsOwnImage).includes(payment);
  }
}
